import Vue from 'vue'
import router from '../router/index';
import ability from "@/libs/acl/ability";
// axios
import axios from 'axios'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import useJwt from "@/auth/jwt/useJwt";
import {initialAbility} from "@/libs/acl/config";

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {'Accept-Language': 'en'},
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.response.use(response => response, error => {
    if (error.response.status === 401) {
        // Remove userData from localStorage
        // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
        localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

        // Remove userData from localStorage
        localStorage.removeItem('userData')


        // Redirect to login page
        router.push({name: 'auth-login'})

        // Reset ability
        ability.update(initialAbility)

    }
    Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
            title: 'Error',
            icon: 'AlertCircleIcon',
            variant: 'danger',
            text: error.response.data.message
        },
    })
});

Vue.prototype.$http = axiosIns

export default axiosIns
