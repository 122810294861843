/* eslint-disable indent */
import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: `${process.env.BASE_URL}`,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        {
            path: '/home',
            name: 'home',
            component: () => import('@/views/Home.vue'),
            meta: {
                pageTitle: 'Home',
                breadcrumb: [
                    {
                        text: 'Home',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Home_Page',
            },
        },
        {
            path: '/languages',
            name: 'languages',
            component: () => import('@/views/pages/languages/index.vue'),
            meta: {
                pageTitle: 'Languages',
                breadcrumb: [
                    {
                        text: 'Languages',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Languages',
            },
        },
        {
            path: '/inquiries',
            name: 'inquiries',
            component: () => import('@/views/pages/inquiries/index.vue'),
            meta: {
                pageTitle: 'Inquiries',
                breadcrumb: [
                    {
                        text: 'Inquiries',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Inquiries',
            },
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('@/views/pages/pages/about.vue'),
            meta: {
                pageTitle: 'About Us',
                breadcrumb: [
                    {
                        text: 'About',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Website_Pages',
            },
        },
        {
            path: '/terms-and-conditions',
            name: 'terms',
            component: () => import('@/views/pages/pages/terms.vue'),
            meta: {
                pageTitle: 'Terms & Conditions',
                breadcrumb: [
                    {
                        text: 'Terms & Conditions',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Website_Pages',
            },
        },
        {
            path: '/privacy-policy',
            name: 'policy',
            component: () => import('@/views/pages/pages/privacyPolicy.vue'),
            meta: {
                pageTitle: 'Privacy Policy',
                breadcrumb: [
                    {
                        text: 'Privacy Policy',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Website_Pages',
            },
        },
        {
            path: '/admins',
            name: 'admins',
            component: () => import('@/views/pages/admins/index.vue'),
            meta: {
                pageTitle: 'Admins',
                breadcrumb: [
                    {
                        text: 'Admins',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Admins',
            },
        },
        {
            path: '/clients',
            name: 'clients',
            component: () => import('@/views/pages/clients/index.vue'),
            meta: {
                pageTitle: 'Clients',
                breadcrumb: [
                    {
                        text: 'Clients',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Users',
            },
        },
        {
            path: '/roles',
            name: 'roles',
            component: () => import('@/views/pages/roles/index.vue'),
            meta: {
                pageTitle: 'Roles',
                breadcrumb: [
                    {
                        text: 'Roles',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Roles',
            },
        },
        {
            path: '/slider',
            name: 'slider',
            component: () => import('@/views/pages/slider/index.vue'),
            meta: {
                pageTitle: 'Slider',
                breadcrumb: [
                    {
                        text: 'Slider',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Slider',
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/pages/settings/index.vue'),
            meta: {
                pageTitle: 'Basic Information',
                breadcrumb: [
                    {
                        text: 'Basic Information',
                        active: true,
                    },

                ],
                resource: 'all',
                action: 'Manage_Settings',
            },
        },
        {
            path: '/devices',
            name: 'devices',
            component: () => import('@/views/pages/devices/index.vue'),
            meta: {
                pageTitle: 'Devices',
                breadcrumb: [
                    {
                        text: 'Devices',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Devices',
            },
        },
        {
            path: '/create-device',
            name: 'create-device',
            component: () => import('@/views/pages/devices/create.vue'),
            meta: {
                pageTitle: 'Create Device',
                breadcrumb: [
                    {
                        text: 'Create Device',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Device',
            },
        },
        {
            path: '/edit-device/:id',
            name: 'edit-device',
            component: () => import('@/views/pages/devices/edit.vue'),
            meta: {
                pageTitle: 'Edit Device',
                breadcrumb: [
                    {
                        text: 'Edit Device',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Device',
            },
        },
        {
            path: '/edit-device',
            name: 'edit-device',
            component: () => import('@/views/pages/devices/edit.vue'),
            meta: {
                pageTitle: 'Edit Device',
                breadcrumb: [
                    {
                        text: 'Edit Device',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Device',
            },
        },
        {
            path: '/scents',
            name: 'scents',
            component: () => import('@/views/pages/scents/index.vue'),
            meta: {
                pageTitle: 'Scents',
                breadcrumb: [
                    {
                        text: 'Scents',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Scents',
            },
        },
        {
            path: '/create-scent',
            name: 'create-scent',
            component: () => import('@/views/pages/scents/create.vue'),
            meta: {
                pageTitle: 'Create Scent',
                breadcrumb: [
                    {
                        text: 'Create Scent',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Scents',
            },
        },
        {
            path: '/edit-scent',
            name: 'edit-scent',
            component: () => import('@/views/pages/scents/edit.vue'),
            meta: {
                pageTitle: 'Edit Scent',
                breadcrumb: [
                    {
                        text: 'Edit Scent',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Scents',
            },
        },
        {
            path: '/rooms',
            name: 'rooms',
            component: () => import('@/views/pages/rooms/index.vue'),
            meta: {
                pageTitle: 'Rooms',
                breadcrumb: [
                    {
                        text: 'rooms',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Rooms',
            },
        },
        {
            path: '/create-room',
            name: 'create-room',
            component: () => import('@/views/pages/rooms/create.vue'),
            meta: {
                pageTitle: 'Create Room',
                breadcrumb: [
                    {
                        text: 'Create Room',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Rooms',
            },
        },
        {
            path: '/edit-room',
            name: 'edit-room',
            component: () => import('@/views/pages/rooms/edit.vue'),
            meta: {
                pageTitle: 'Edit Room',
                breadcrumb: [
                    {
                        text: 'Edit Room',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_Rooms',
            },
        },
        {
            path: '/walkThrough',
            name: 'walkThrough',
            component: () => import('@/views/pages/walkThrough/index.vue'),
            meta: {
                pageTitle: 'WalkThrough',
                breadcrumb: [
                    {
                        text: 'WalkThrough',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_WalkThrough',
            },
        },
        {
            path: '/create-walk-through',
            name: 'create-walk-through',
            component: () => import('@/views/pages/walkThrough/create.vue'),
            meta: {
                pageTitle: 'Create Walk Through',
                breadcrumb: [
                    {
                        text: 'Create Walk Through',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_WalkThrough',
            },
        },
        {
            path: '/edit-walk-through/:id',
            name: 'edit-walk-through',
            component: () => import('@/views/pages/walkThrough/edit.vue'),
            meta: {
                pageTitle: 'Edit Walk Through',
                breadcrumb: [
                    {
                        text: 'Edit Walk Through',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_WalkThrough',
            },
        },
        {
            path: '/user-invoices/:id',
            name: 'user-invoices',
            props: route => ({
                page_title: route.params.page_title,
            }),
            component: () => import('@/views/pages/clients/invoices.vue'),
            meta: {
                pageTitle: 'Invoices',
                breadcrumb: [
                    {
                        text: 'Invoices',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_clients',
            },
        },
        {
            path: '/invoice-devices/:id',
            name: 'invoice-devices',
            props: route => ({
                page_title: route.params.page_title,
            }),
            component: () => import('@/views/pages/clients/devices.vue'),
            meta: {
                pageTitle: 'Devices',
                breadcrumb: [
                    {
                        text: 'Devices',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_clients',
            },
        },
        {
            path: '/invoice-scents/:id',
            name: 'invoice-scents',
            props: route => ({
                page_title: route.params.page_title,
            }),
            component: () => import('@/views/pages/clients/scents.vue'),
            meta: {
                pageTitle: 'Scents',
                breadcrumb: [
                    {
                        text: 'Scents',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_clients',
            },
        },
        {
            path: '/user-rooms/:id',
            name: 'user-rooms',
            component: () => import('@/views/pages/clients/rooms.vue'),
            meta: {
                pageTitle: 'Rooms',
                breadcrumb: [
                    {
                        text: 'Rooms',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_clients',
            },
        },
        {
            path: '/general-notifications',
            name: 'general_notifications',
            component: () => import('@/views/pages/notifications/index.vue'),
            meta: {
                pageTitle: 'General notifications',
                breadcrumb: [
                    {
                        text: 'General notifications',
                        active: true,
                    },
                ],
                resource: 'all',
                action: 'Manage_general_notifications',
            },
        },

        {
            path: '/forgot-password',
            name: 'auth-forgot-password',
            component: () => import('@/views/pages/authentication/ForgotPassword.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/profile',
            name: 'profile',
            component: () => import('@/views/pages/profile/profile.vue'),
            meta: {
                pageTitle: 'Profile',
                breadcrumb: [
                    {
                        text: 'Profile',
                        active: true,
                    },
                ],
                resource: 'all',

            },
        },
        {
            path: '/dashboard/login',
            name: 'auth-login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/login',
            name: 'auth-login',
            component: () => import('@/views/Login.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/register',
            name: 'auth-register',
            component: () => import('@/views/pages/authentication/Register.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '/',
            name: 'intro',
            component: () => import('@/views/Intro.vue'),
            meta: {
                layout: 'full',
                resource: 'Auth',
                redirectIfLoggedIn: true,
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },

    ],
})
router.beforeEach((to, _, next) => {
    console.log(to.fullPath)
    // if (to.fullPath === '/') return next({ name: 'intro' })
    const isLoggedIn = isUserLoggedIn()
    if ((isLoggedIn !== null) && to.fullPath === '/') {
        return next({ name: 'home' })

    }

    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next({ name: 'intro' })

        // If logged in => not authorized
        // return next({ name: 'misc-not-authorized' })
    }

    if (to.params.page_title !== null && to.params.page_title !== 'undefined') {
        if (['user-invoices', 'user-rooms', 'invoice-devices', 'invoice-scents'].includes(to.name)) {
            // eslint-disable-next-line no-param-reassign
            to.meta.pageTitle = to.params.page_title
        }
    }
    // Redirect if logged in
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
        const userData = getUserData()
        next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
    }

    return next()
})
// router.beforeEach((to, _, next) => {
//     const isLoggedIn = isUserLoggedIn()
//     if (!canNavigate(to)) {
//         // Redirect to login if not logged in
//         if (!isLoggedIn) return next({name: 'auth-login'})
//
//         // If logged in => not authorized
//         return next({name: 'misc-not-authorized'})
//     }
//
//     // Redirect if logged in
//     if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//         const userData = getUserData()
//         next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//     }
//
//     return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading

    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
